/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"
import styled, { ThemeProvider } from "styled-components"

import "./layout.css"
import { FlexRow } from "./styles"

const theme = {
  bg: "#F2F2F2",
  fg: "#222222",
  fgLight: "rgba(105,105,105, 1)" 
}

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 680px;
  display: flex;
  flex-direction: column;
  height: 100%;

  main {
    flex-grow: 1;
  }
`

const Footer = styled(FlexRow)`
  min-height: 100px;
  border-top: ${({ theme }) => `1px solid ${theme.fgLight}`};
  color: ${({ theme }) => `${theme.fgLight}`};
`

const Header = styled(FlexRow)`
  width: 100%;
  margin: 8px 0;
  border-bottom: ${({ theme }) => `1px solid ${theme.fgLight}`};
  gap: 8px;
  color: ${({ theme }) => `${theme.fgLight}`};
`;


const Layout = ({ children}) => {
  return (
  <StaticQuery
    query={graphql`
    query siteTitleQueryAndIndexPageQuery {
        site {
          siteMetadata {
            title
          }
        }
     allMarkdownRemark(filter: {fileAbsolutePath:  {regex: "/assets/" }}, sort: { order: ASC, fields: [frontmatter___order]}) {
       edges {
           node {
             frontmatter {
               title
               path
             }
           }
         }
       }

      }
    `}
    render={data => (
      <ThemeProvider theme={theme}>
        <Wrapper>
          <Header>
            {data.allMarkdownRemark.edges.map(({ node: page })=> (
              <p>
                  <Link
                    to={page.frontmatter.path}
                    style={{ color: "inherit", textDecoration: "none" }}
                    activeStyle={{ color: "inherit", textDecoration: 'underline'}}
                    
                  >
                    {page.frontmatter.title}
                  </Link>
 
              </p>
        ))}
          </Header>
          <main>{children}</main>
          <Footer>
            <p>
              <Link
                to="/"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                troygibb
              </Link>
            </p>
          </Footer>
        </Wrapper>
      </ThemeProvider>
    )}
  />
)
    }

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}



export default Layout
